import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
//import ReactGA from 'react-ga';
import ContentHeade from '../../components/ContentHeader/index.js';
import Slider from 'rc-slider';
import Select from 'react-select';
import Skeleton from '../../components/Skeleton/index.js';
import NumberFormat from 'react-number-format';
import Paginate from 'react-js-pagination';
import QueryString from 'querystring';

import { api, apiId, urlImgs,existsOrError,buscaValores, moneyFormatter, titleSite, itensPorPagina, handleUrl, buscaCidades, buscaBairros, descriptionDefault, urlSite, reloadTime, urlFavicon } from '../../utils/index.js';

import SmFoto from '../../assets/img/sm-foto.jpg';
import Place from '../../assets/img/place.svg';

const Range = Slider.Range;

const customStyles = {
    
    menuPortal: provided => ({ ...provided, zIndex: 9999, fontSize: 14 }),
    menuList: (base) => ({
        ...base,
    
        "::-webkit-scrollbar": {
          width: "4px",
          height: "0px",
        },
        "::-webkit-scrollbar-track": {
          background: "#f1f1f1"
        },
        "::-webkit-scrollbar-thumb": {
          background: "#888"
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: "#555"
        }
      })
    
  }


export default function Imoveis(props) {    

    // eslint-disable-next-line
    const storage = useSelector(state => state.data);
    const isOpen = useSelector(state => state.open);

    const queryInicial = QueryString.parse(props.location.search.replace('?',''));
    if (queryInicial.pg) delete queryInicial.pg;
    if (queryInicial.ordenacao) delete queryInicial.ordenacao;

    const [ formulario, setFormulario ] = useState(queryInicial);  
    const [ pageSkeleton, setPageSkeleton ] = useState(true);        
    const [ totalImoveis, setTotalImoveis ] = useState(0);
    const [ listaImoveis, setImoveis ] = useState([]);
    // const [ filtros, setFiltros ] = useState([ 
    const [ filtros ] = useState([                
        { value: 'default', label: 'FILTRAR' },
        { value: 'recentes', label: 'Recentes' },
        { value: 'relevancia', label: 'Relevância' },
        { value: 'menorvalor', label: 'Menor Valor' },
        { value: 'maiorvalor', label: 'Maior Valor' },
    ]);
    const [ pagina, setPagina ] = useState(0);
    const [ filtrado, setFiltrado ] = useState('');
    const [ busca, setBusca ] = useState('');

   /****** BUSCA *******/

   const [ loading, setLoading ] = useState(true);    
   const [ finalidade, setFinalidade ] = useState([]);
   const [ tipoImovel, setTipoImovel ] = useState([]);
   const [ uf, setUf ] = useState([]);
   const [ cidade, setCidade ] = useState([]);        
   const [ bairro, setBairro ] = useState([]); 
   const [ valores, getValores ] = useState({});

   const [rangeLimits, setRangeLimits] = useState({min: 0, max: 100});
           
    useEffect(() => {
        if (Object.keys(storage).length > 0) {
            const query = QueryString.parse(props.location.search.replace('?',''));        
            getDados(query);                        
            setValores();
            if (formulario.uf) getCidade(formulario.uf);
            if (formulario.cidade) getBairro(formulario.cidade); 
        } 
        handleScroll();  
     
    },[]);

    const refStorage = useRef(true);
    useEffect(() => {        
        if (refStorage.current) { refStorage.current = false;return; }
        const query = QueryString.parse(props.location.search.replace('?',''));        
        getDados(query);
        setValores();
                                
    },[storage]);


    function handleSlideChange(values) {
        if(rangeLimits.min == rangeLimits.max) return '';  
        getValores({ min: values.min, max: values.max });
        setFormulario({ ...formulario, valorde: values.min, valorate: values.max });
    }

    
    async function setValores() {
        setFinalidade(storage.finalidades);
        setTipoImovel(storage.tipoimoveis);
        setUf(storage.estados);
           
        if (existsOrError(storage.valores )) {
            const response = await buscaValores(formulario.finalidade);
            setRangeLimits({min: parseInt(response.valor_minimo), max: parseInt(response.valor_maximo)});
                     
        }        
    }   
   
 
    useEffect(() => {

        handleRequisicao()

    }, [busca]);

    useEffect(() => {
        if (window.innerWidth > 770) return 
        handleRequisicao()
        
    }, [formulario]);
    function handleRequisicao(){
        setPageSkeleton(true);        
        const novaUrl = new Array();
        pagina && novaUrl.push(`pg=${pagina}`);
        (filtrado && filtrado != 'default') && novaUrl.push(`ordenacao=${filtrado}`);        
        window.history.pushState("", "", `/busca?${QueryString.stringify(formulario)}${novaUrl.length > 0 ? `&${novaUrl.join('&')}` : ''}`);           
        if (formulario.uf) getCidade(formulario.uf);
        if (formulario.cidade) getBairro(formulario.cidade);
        if (formulario.valorde || formulario.valorate ) getValores({min: formulario.valorde, max: formulario.valorate}); 
        const newSearch = handleUrl(`?${novaUrl.join('&')}`);
        getDados({ ...formulario, ...newSearch });
    
        handleScroll()
    }
   

    function handlePaginacao(value) { 
        setPagina(value);
        setBusca(value);
    }

    function handleOrdenacao(value) {
        setFiltrado(value);
        setBusca(value);
    }

    function handleScroll() {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }

   async function getCidade(valor) {
        const response = await buscaCidades(valor);            
        setCidade(response); 
    }

    async function getBairro(valor) {
        const response = await buscaBairros(valor);            
        response.unshift({value:'',label:'BAIRRO'});
     
        setBairro(response); 
    }
    


    async function handleOptionChange(tipo, valor) {        
       
        switch (tipo) {
            
            case 'finalidade':
                const response = await buscaValores(valor);
                
                getValores({ min: 0, max: 100 });
                setRangeLimits({min: parseInt(response.valor_minimo), max: parseInt(response.valor_maximo)});
                setFormulario({ ...formulario, finalidade: valor, valorde: parseInt(response.valor_minimo), valorate: parseInt(response.valor_maximo) });
                break;

            case 'tipo':
                setFormulario({ ...formulario, tipo: valor });
                break;

            case 'uf':
                setFormulario({ ...formulario, uf: valor });
                setCidade([{value: '', label: 'Carregando'}]);
                const respUf = await buscaCidades(valor);
                setCidade(respUf); 
                break;

            case 'cidade':
                setFormulario({ ...formulario, cidade: valor });
                setBairro([{value: '', label: 'Carregando'}]);
                const respCidade = await buscaBairros(valor);            
                setBairro(respCidade);
                break;

            case 'bairro':
                setFormulario({ ...formulario, bairro: valor }); 
                break;

            case 'valorde':
                setFormulario({ ...formulario, valorde: valor });
                break;

            case 'valorate':
                setFormulario({ ...formulario, valorate: valor }); 
                break;
                
            default:
                
                break;
        }
        
      
       
    }

    const valuesRange = () => {
        let values = [0,0]
    
        if(formulario.valorde){
            if(rangeLimits.min == rangeLimits.max ){
                values[0] = 0
            }else{
                values[0] = formulario.valorde
            }
            
        }else{
            if(rangeLimits.min == rangeLimits.max){
                values[0] = 0
            }else{
                values[0] = rangeLimits.min ? rangeLimits.min : 0
            }
        }
    
        if(formulario.valorate){
            values[1] = formulario.valorate
        }else{
            values[1] = rangeLimits.max ? rangeLimits.max : 0
        }
        
        return values
    }


    async function handleSubmit() {
        setLoading(true);
        setPageSkeleton(true);
        
        const novaUrl = new Array();
        pagina && novaUrl.push(`pg=${pagina}`);
        (filtrado && filtrado != 'default') && novaUrl.push(`ordenacao=${filtrado}`);        
        window.history.pushState("", "", `/busca?${QueryString.stringify(formulario)}${novaUrl.length > 0 ? `&${novaUrl.join('&')}` : ''}`);
        
        const newSearch = handleUrl(`?${novaUrl.join('&')}`);
        getDados({ ...formulario, ...newSearch });
        
    }

    function getDados({ ...search }) {        
        setLoading(true);
        setPageSkeleton(true);
        api.post('',{            
            acoes: [                        
                { 
                    metodo: "busca", 
                    params: [ 
                        { 
                            ...search,                            
                            resultados: itensPorPagina
                        }
                    ] 
                },                
            ],
            id: apiId                 
        }).then(resp => {              
            setTotalImoveis(resp.data.busca.total_registros);
            setImoveis(resp.data.busca.total_registros ? resp.data.busca.imoveis : []);
            setTimeout(() => {setPageSkeleton(false)}, 100);
            setTimeout(() => {setLoading(false)}, 100);
        }).catch(e => {
            setTimeout(() => { getDados({ ...search }) }, reloadTime);
            //console.log(e);
        });
    }

    let renderSkeletonList = [];
    for (let i = 0; i < itensPorPagina; i++) { renderSkeletonList[i] = i; }

    
    return (        
        <> 
            <Helmet>             
                
                <link rel="apple-touch-icon" sizes="57x57" href={ `${urlFavicon}apple-icon-57x57.png`} />
                <link rel="apple-touch-icon" sizes="60x60" href={ `${urlFavicon}apple-icon-60x60.png` } />
                <link rel="apple-touch-icon" sizes="72x72" href={ `${urlFavicon}apple-icon-72x72.png` } />
                <link rel="apple-touch-icon" sizes="76x76" href={ `${urlFavicon}apple-icon-76x76.png` } />
                <link rel="apple-touch-icon" sizes="114x114" href={ `${urlFavicon}apple-icon-114x114.png` } />
                <link rel="apple-touch-icon" sizes="120x120" href={ `${urlFavicon}apple-icon-120x120.png` } />
                <link rel="apple-touch-icon" sizes="144x144" href={ `${urlFavicon}apple-icon-144x144.png` } />
                <link rel="apple-touch-icon" sizes="152x152" href={ `${urlFavicon}apple-icon-152x152.png` } />
                <link rel="apple-touch-icon" sizes="180x180" href={ `${urlFavicon}apple-icon-180x180.png` } />
                <link rel="icon" type="image/png" sizes="192x192"  href={ `${urlFavicon}android-icon-192x192.png` } />
                <link rel="icon" type="image/png" sizes="32x32" href={ `${urlFavicon}favicon-32x32.png` } />
                <link rel="icon" type="image/png" sizes="96x96" href={ `${urlFavicon}favicon-96x96.png` } />
                <link rel="icon" type="image/png" sizes="16x16" href={ `${urlFavicon}favicon-16x16.png` } />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content={ `${urlFavicon}ms-icon-144x144.png` } />
                <meta name="theme-color" content="#ffffff" />

                <meta name="description" content={descriptionDefault} />
                <meta name="og:site_name" property="og:site_name" content={titleSite} />
                <meta name="og:title" property="og:title" content={`Resultado da Busca | ${titleSite}`} />
                <meta name="og:url" property="og:url" content={`${urlSite}/busca${QueryString.parse(props.location.search)}`} />
                <meta name="og:description" property="og:description" content={descriptionDefault} />                             
                <meta name="og:image" property="og:image" content={`${urlFavicon}padrao.png`} />
                <meta name="og:image:width" property="og:image:width" content="300" />
                <meta name="og:image:height" property="og:image:height" content="300" />
                <title>Resultado da Busca | { titleSite }</title>
            </Helmet>
            
            <div className={`${isOpen ? 'open': ''}main`}>
       
                <ContentHeade title="Resultado da Busca" noSearch={true} routes={props} callbackchage={ (dadosFormulario) => setFormulario(dadosFormulario) } busca dadosFiltrados={formulario}/>
                
                <div className="container px-4 px-sm-0">

                    <div className="d-none d-md-block searchbox mt-2 mt-md-5 ">
                        <div className="row shadow mx-0 p-4">
                            
                            <div className="col-3">
                                <Select className="select" classNamePrefix="react-select" value={finalidade.find(item => item.value == formulario.finalidade)} placeholder="FINALIDADE" onChange={e => handleOptionChange('finalidade',e.value)} options={finalidade} menuPortalTarget={document.body}
                                styles={customStyles} />
                            </div>
                            <div className="col-3">
                                <Select className="select" classNamePrefix="react-select" value={tipoImovel.find(item => item.value == formulario.tipo)} placeholder="TIPO IMÓVEL" onChange={e => handleOptionChange('tipo',e.value)} options={tipoImovel} menuPortalTarget={document.body}
                                styles={customStyles} />
                            </div>
                            <div className="col-2">
                                <Select className="select" classNamePrefix="react-select" value={uf.find(item => item.value == formulario.uf)} placeholder="UF" onChange={e => handleOptionChange('uf',e.value)} options={uf} menuPortalTarget={document.body}
                                styles={customStyles} />
                            </div>
                            <div className="col-4">
                                <Select className="select" classNamePrefix="react-select" value={cidade?.find(item => item.value == formulario.cidade)} placeholder="CIDADE" onChange={e => handleOptionChange('cidade',e.value)} options={cidade} noOptionsMessage={() => 'Selecione'} menuPortalTarget={document.body}
                                styles={customStyles}/>
                            </div>
                            <div className="col-3 pt-3">
                                <Select className="select" classNamePrefix="react-select" value={bairro?.find(item => item.value == formulario.bairro)} placeholder="BAIRRO" onChange={e => handleOptionChange('bairro',e.value)} options={bairro} noOptionsMessage={() => 'Selecione'}  
                                menuPortalTarget={document.body}
                                styles={customStyles}
                                />                                        
                            </div>
                   
                            <div className="col-5">
                                <label className="d-block  imputValorDesejadoB font-14 select">VALOR DESEJADO</label>  
                                <div>
                                    <Range allowCross={false}  min={valuesRange()[0]==0? 0:rangeLimits.min} max={rangeLimits.max} value={valuesRange()} onChange={e => handleSlideChange({min: e[0], max: e[1]}) } />
                                    <div className="d-flex justify-content-between font-12 pt-1 pb-1 text-center">
                                        <NumberFormat  disabled className="bg-transparent w-50 font-12 d-inline-block border-0 p-0 m-0 font-weight-bolder color-active" thousandSeparator="." decimalSeparator="," allowNegative={false} prefix={'R$ '} value={valuesRange()[0] } />
                                        <NumberFormat disabled className="bg-transparent w-50 text-right font-12 d-inline-block border-0 p-0 m-0 font-weight-bolder color-active" thousandSeparator="." decimalSeparator="," allowNegative={false} prefix={'R$ '} value={valuesRange()[1]} />                                            
                                    </div>  
                                </div>
                            </div> 

                            <div className="col-3 pt-4 ml-auto">
                                <button type="button" className="btn btn-primary font-14 w-100 py-2" onClick={() => handleSubmit()} disabled={ loading ? true : false }>
                                    { loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span> }
                                    { loading ? 'BUSCANDO' : 'BUSCAR AGORA' }
                                </button>
                            </div>
                        </div>
                    </div>
                    { 
                        pageSkeleton &&
                        <>
                        <header className={`${ pageSkeleton ? 'd-flex ' : 'd-none '}justify-content-between align-items-md-center flex-column flex-md-row pt-2 pt-md-5`}>
                            <Skeleton width={195} height={27} />
                            <Skeleton width={200} height={38} />
                        </header>
                        
                        

                    
                        <div className={`${ pageSkeleton ? '' : 'd-none '}row pt-2 pb-5`}>
                            { renderSkeletonList.map((imovel, index) => {
                                return (
                                    <div key={index} className="col-12 col-md-6 col-xl-3 py-4">
                                        <div className="d-flex flex-column shadow h-100 item-grid">
                                            <div className="foto position-relative">
                                                <Skeleton className="skeleton-absolute" />
                                            </div>
                                            <div className="d-flex flex-grow-1 flex-column px-3 py-3">
                                                
                                                <div className="flex-grow-2">
                                                    <Skeleton width={100} height={12} />
                                                    <Skeleton className="mt-1" width={120} height={24} />                        
                                                </div>

                                                <div className="d-flex infos flex-grow-1 align-items-center py-3">
                                                    <Skeleton width={177} height={11} />
                                                </div>
                                                
                                                <div className="endereco font-12 line-height-130 pl-0">
                                                    <Skeleton width={200} height={32} /> 
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) }
                        </div>
                        </> 
                    }


                    { listaImoveis.length > 0 ?  (
                        <>
                        <header className={`${ pageSkeleton ? 'd-none ' : 'd-flex '}topo-grid justify-content-between align-items-md-center flex-column flex-md-row pt-2 pt-md-5`}>
                            <div>  
                                <Select className="select filtro" classNamePrefix="react-select" defaultInputValue={filtrado ? filtrado : ''} onChange={(e) => handleOrdenacao(e.value)} name="" placeholder="FILTRAR" options={filtros} />                                
                            </div>
                            <div className="font-14 font-md-18 qtde mt-md-0 mt-3">
                                <b className="pr-2">{totalImoveis > 1 ? `${totalImoveis} imóveis` : `${totalImoveis} imóvel` }</b>                                                               
                            </div>
                        </header>
                        <div className={`${ pageSkeleton ? 'd-none ' : ''}row pt-2 pb-5`}>
                            
                            { listaImoveis.map(imovel => {
                                return (
                                    <div key={imovel.id} className="col-12 col-md-6 col-xl-3 py-4">
                                        <Link to={`/imovel/${imovel.id}`} className="d-flex flex-column shadow h-100 item-grid">
                                            
                                            <div className="foto position-relative">
                                                { imovel.imagem ? (
                                                    <img src={`${urlImgs}/${imovel.imagem}`} alt={imovel.tipo} /> 
                                                
                                                ) : <img src={SmFoto} alt={imovel.tipo} /> }      
                                                <Skeleton className="skeleton-absolute" />
                                            </div>
                                            <div className="d-flex flex-grow-1 flex-column px-3 py-3">
                                                
                                                <div className="flex-grow-2">
                                                    <div className="font-12 font-md-11 line-height-100">{imovel.finalidade}{imovel.tipo && <small className="ml-1 font-italic opacity-50">({imovel.tipo})</small>}</div>
                                                    <div className="font-20 color-primary">
                                                        { imovel.valor ? (
                                                            <b>R$ {moneyFormatter(imovel.valor)}</b>
                                                        ) :
                                                        (
                                                            <b>Consulte-nos</b>
                                                        ) }
                                                        
                                                    </div>
                                                    { imovel.valor_condominio && <div className="font-12 font-md-11 line-height-100">Condomínio: R$ {moneyFormatter(imovel.valor_condominio)}</div> }
                                                </div>

                                                <div className="d-flex infos flex-grow-1 align-items-center py-3">
                                                    <div className="d-flex">
                                                        { imovel.dormitorios != 0 && <div className="info info-dormitorios font-12 font-md-11 line-height-100 pr-3"><div>{imovel.dormitorios}</div></div> }
                                                        { imovel.banheiros != 0 && <div className="info info-banheiros font-12 font-md-11 line-height-100 pr-3"><div>{imovel.banheiros}</div></div> }
                                                        { imovel.area != 0 && <div className="info info-area font-12 font-md-11 line-height-100 pr-3"><div>{imovel.area} m<sup>2</sup></div></div> }
                                                    </div>
                                                </div>
                                                
                                                <div className="endereco font-12 line-height-130">
                                                    <img src={Place} alt="" />
                                                    {`${imovel.bairro} | ${imovel.cidade}/${imovel.uf}`}
                                                </div>
                                                
                                            </div>
                                            
                                        </Link>
                                    </div>
                                );

                            }) }

                        </div>

                        { totalImoveis > itensPorPagina && (
                            <div className="d-flex justify-content-center pt-2 pb-5 pagination-container">
                                <Paginate                                     
                                    hideFirstLastPages={true}
                                    activePage={pagina ? pagina : 1}
                                    itemsCountPerPage={itensPorPagina}
                                    totalItemsCount={totalImoveis}
                                    pageRangeDisplayed={5}
                                    onChange={e => handlePaginacao(e)}
                                /> 
                            </div> 
                        ) }

                        </>                     
                    ) :(
                        loading ?
                           <div className="text-center py-5 my-5 font-32 opacity-50 "></div>
                       : 
                            <div className="text-center py-5 my-5 font-32 opacity-50">Nenhum imóvel encontrado</div> 
                                     
                   )}                                        

                </div>

            </div>

        </>

    )

    
}